<template>
  <div>
    <Entry v-if="pageIndex === 1" :index="1" @next="nextPage" />
    <SelectRoutingPage v-if="pageIndex === 2" :index="2" @next="handleMethodSelect" />
    <MobileCheckRouting v-if="pageIndex === 3" :index="3" @next="nextPage" @change="pageIndex = 2" />
    <EmailCheckRouting v-if="pageIndex === 4" :index="4" @next="nextPage" @change="pageIndex = 2" />
  </div>
</template>

<script>
import Entry from './Entry'
import SelectRoutingPage from '../components/SelectRoutingPage'
import MobileCheckRouting from './MobilCheckRouting'
import EmailCheckRouting from './EmailCheckRouting'

export default {
  name: 'Mobile',
  components: {
    Entry,
    SelectRoutingPage,
    MobileCheckRouting,
    EmailCheckRouting,
  },
  data() {
    return {
      pageIndex: 1
    }
  },
  methods: {
    nextPage() {
      this.pageIndex += 1
    },
    handleMethodSelect(type) {
      if (type === 'phone') {
        this.pageIndex = 3
      } else {
        this.pageIndex = 4
      }
    },
  }
}
</script>

<style>

</style>
