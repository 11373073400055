<template>
  <el-button
    :class="`button-ugly ${disabled ? 'disabled' : ''}`"
    @click="handleClick"
  >
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) this.$emit('click')
    },
  },
}
</script>

<style scoped>
.button-ugly {
  color: white;
  background: linear-gradient(#05d0fe, #2c6fe8);
}
.button-ugly.disabled {
  background: #dddddd;
}
</style>
