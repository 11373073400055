<template>
  <div class="container">
    <div v-if="userInfo.phone" class="item">
      <img :src="phoneImage" alt="" />
      <div class="desc">
        <h1>{{ $t('0e812ac') }}</h1>
        <p>{{ $t('3603639') }}</p>
      </div>
      <div>
        <ActionButton class="button" @click="handleClick('phone')">{{ $t('1312c8b') }}</ActionButton>
      </div>
    </div>
    <div v-if="userInfo.email" class="item">
      <img :src="emailImage" alt="" />
      <div class="desc">
        <h1>{{ $t('e259bc8') }}</h1>
        <p>{{ $t('8d5a553') }}</p>
      </div>
      <div>
        <ActionButton class="button" @click="handleClick('email')">{{ $t('1312c8b') }}</ActionButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ActionButton from '@/components/ActionButton'
import phoneImage from '@/assets/usercenter/phone.png'
import emailImage from '@/assets/usercenter/email.png'

export default {
  name: 'Method',
  components: {
    ActionButton,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      phoneImage,
      emailImage,
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    handleClick(type) {
      this.$emit('next', type)
    },
  },
}
</script>

<style scoped lang="less">
.container {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    img {
      width: 66px;
      height: 66px;
    }
    .desc {
      flex-grow: 1;
      padding-left: 24px;
      h1 {
        height: 18px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 27px;
      }
      p {
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        margin-top: 18px;
      }
    }
  }
}
.button {
  width: 120px;
}
</style>
