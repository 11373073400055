<template>
  <div>
    <Steps :steps-content="stepConfig" :active="step - 1" />
    <MobileCheckPage
      v-if="step === 1"
      :index="1"
      type="CHANGE_EMAIl"
      @next="nextPage"
      @change="$emit('change')"
    />
    <SetNewEmailPage v-if="step === 2" :index="2" @next="nextPage" />
    <SuccessPage v-if="step === 3" @next="complete" />
  </div>
</template>

<script>
import Steps from '@/components/Steps/steps'

import MobileCheckPage from '../components/MobileCheckPage'
import SetNewEmailPage from '../components/SetNewEmailPage'
import SuccessPage from '../components/ChangeEmailSuccessPage'

export default {
  name: 'MsgCheck',
  components: {
    Steps,
    MobileCheckPage,
    SetNewEmailPage,
    SuccessPage,
  },
  data() {
    return {
      step: 1,
      stepConfig: {
        step1: this.$t('abd2e34'),
        step2: this.$t('3c9c9d3'),
        step3: this.$t('2d873d5'),
      },
    }
  },
  methods: {
    nextPage() {
      this.step += 1
    },
    complete() {
      this.$emit('complete')
    },
  },
}
</script>

<style>
</style>
