<template>
  <div class="container">
    <el-form
      ref="form"
      label-suffix="："
      label-width="150px"
      :model="values"
      :rules="rules"
    >
      <el-form-item :label="$t('695c531')" prop="password">
        <el-input
          v-model="values.password"
          type="password"
          :placeholder="$t('d3aabb3')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item :label="$t('6868e7b')" prop="confirmPassword">
        <el-input
          v-model="values.confirmPassword"
          type="password"
          :placeholder="$t('20dafd8')"
          style="width: 360px"
        />
      </el-form-item>
    </el-form>
    <ActionButton
      class="button"
      :disabled="disabled"
      @click="changePasswd"
    >{{ $t('127e973') }}</ActionButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import validation from '@/utils/validation'
import ActionButton from '@/components/ActionButton'
import { base64Password } from '@/utils/helper'

import api from '@/api/account'

export default {
  name: 'SetNewPhone',
  components: {
    ActionButton,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      phone: '',
      values: {},
      rules: {
        password: [
          { confirmPassword: true, message: '请输入新密码' },
          { validator: validation.password },
        ],
        confirmPassword: [
          { password: true, message: '请确认新密码' },
          { validator: validation.password },
          {
            validator: (_, value, cb) => {
              this.values.password !== value ? cb('输入密码不一致') : cb()
            },
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    disabled() {
      const a = this.values.confirmPassword
      const b = this.values.password
      return !(a && b && a === b)
    },
  },
  methods: {
    handleClick() {
      this.$emit('next')
    },
    changePasswd() {
      api.changePasswd({
        confirmPassword: base64Password(this.values.confirmPassword),
        password: base64Password(this.values.password)
      }).then((res) => {
        if (res && res.code === '000000') {
          this.$emit('next')
        } else {
          this.$message.warning(res.message)
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 510px;
  margin: 0 auto;
  margin-top: 60px;
  /deep/ .el-input-group__append {
    padding: 0;
  }
}
.button {
  width: 360px;
}
.send-code-btn {
  cursor: pointer;
  padding: 0 10px;
  display: inline-block;
  width: 80px;
  text-align: center;
}
</style>
