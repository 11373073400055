<template>
  <div>
    <Steps :steps-content="stepConfig" :active="step - 1" />
    <MobileCheckPage
      v-if="step === 1"
      :index="1"
      type="CHANGE_PHONE"
      @next="nextPage"
      @change="$emit('change')"
    />
    <SetNewPhone
      v-if="step === 2"
      :index="2"
      type="CHANGE_PHONE"
      @next="nextPage"
    />
    <SuccessPage v-if="step === 3" @next="complete" />
  </div>
</template>

<script>
import Steps from '@/components/Steps/steps'

import MobileCheckPage from '../components/MobileCheckPage'
import SetNewPhone from '../components/SetNewPhonePage'
import SuccessPage from '../components/ChangePhoneSuccessPage'

export default {
  name: 'MsgCheck',
  components: {
    Steps,
    MobileCheckPage,
    SetNewPhone,
    SuccessPage,
  },
  data() {
    return {
      step: 1,
    }
  },
  computed: {
    stepConfig() {
      return {
        step1: this.$t('abd2e34'),
        step2: this.$t('d1041bc'),
        step3: this.$t('2d873d5'),
      }
    }
  },
  methods: {
    nextPage() {
      this.step += 1
    },
    complete() {
      this.$emit('complete')
    },
  },
}
</script>

<style>
</style>
