<template>
  <span @click="handleClick">
    <template v-if="count !== 0">{{ count }}s</template>
    <slot v-else></slot>
  </span>
</template>

<script>
export default {
  name: 'CountDown',
  props: {
    asyncCall: Function,
  },
  data() {
    return {
      count: 0,
      timer: undefined,
      lock: false,
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    handleClick() {
      if (this.lock) return
      this.lock = true
      this.asyncCall().then(res => {
        if (res) {
          this.count = 60
          this.beginTimer()
          this.$message.success('发送成功')
        } else {
          this.lock = false
        }
      })
    },
    beginTimer() {
      this.timer = setInterval(() => {
        this.count -= 1
        if (this.count === 0) {
          clearInterval(this.timer)
          this.lock = false
        }
      }, 1000)
    },
    isLocked() {
      return this.lock
    },
  }
}
</script>

<style>

</style>
