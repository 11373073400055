<template>
  <div class="container">
    <el-form
      ref="form"
      label-suffix="："
      label-width="150px"
      :model="values"
      :rules="rules"
    >
      <el-form-item :label="$t('e4ef183')">
        <p>{{ userInfo.phone }}</p>
      </el-form-item>
      <el-form-item :label="$t('d34532d')" prop="gCode">
        <el-input v-model="values.gCode" style="width: 360px">
          <template slot="append">
            <GraphicalCode />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item required :label="$t('01fdd9f')">
        <el-input v-model="values.smsCode" style="width: 360px">
          <span slot="append" class="send-code-btn">
            <CountDown :async-call="sendCode">{{ $t('2ad10e6') }}</CountDown>
          </span>
        </el-input>
      </el-form-item>
    </el-form>
    <ActionButton class="button" :disabled="disabled" @click="checkSmsCode">
      {{ $t('127e973') }}
    </ActionButton>
    <p class="notifier">
      {{ $t('b299e43') }}
      <span class="text-button" @click="handleChangeMethod">{{ $t('af8b319') }}</span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ActionButton from '@/components/ActionButton'
import GraphicalCode from '@/components/GraphicalCode'
import CountDown from '@/components/CountDown'

import api from '@/api/account'

export default {
  name: 'MobileCheckPage',
  components: {
    ActionButton,
    GraphicalCode,
    CountDown,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      phone: '',
      values: {},
      rules: {
        gCode: [{ required: true, message: '请输入图形验证码' }],
        smsCode: [{ required: true, message: '请输入短信验证码' }],
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    disabled() {
      return !this.values.gCode || !this.values.smsCode
    },
  },
  methods: {
    handleClick() {
      this.$emit('next')
    },
    checkAndSendCode() {
      return api.checkVerify(this.values.gCode).then((res) => {
        // debugger
        if (res && !res.code) {
          return api
            .sendSms({ phone: this.userInfo.phone, smsType: this.type })
            .then((res) => {
              return res.code === '000000'
            })
        } else {
          this.$message.warning(res.message)
          return Promise.resolve(false)
        }
      })
    },
    sendCode() {
      return new Promise((resolve, reject) => {
        this.$refs['form'].validateField('gCode', (msg) => {
          if (!msg) {
            this.checkAndSendCode().then((res) => {
              resolve(res)
            })
          } else {
            resolve(false)
          }
        })
      })
    },
    checkSmsCode() {
      api
        .cheekedSmsCode({
          phone: this.userInfo.phone,
          smsCode: this.values.smsCode,
          smsType: this.type,
        })
        .then((res) => {
          // debugger
          if (res && res.code === '000000') {
            this.$emit('next')
          } else {
            this.$message.warning(res.message)
          }
        })
    },
    handleChangeMethod() {
      this.$emit('change')
    },
  },
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 510px;
  margin: 60px auto 60px auto;
  /deep/ .el-input-group__append {
    padding: 0;
  }
}
.button {
  width: 360px;
}
.text-button {
  cursor: pointer;
  color: #00a4ff;
}
.send-code-btn {
  cursor: pointer;
  padding: 0 10px;
  display: inline-block;
  width: 80px;
  text-align: center;
}
.notifier {
  width: 360px;
  text-align: center;
  margin: 10px;
}
</style>
