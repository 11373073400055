<template>
  <div class="container">
    <img :src="image" alt="" />
    <h1>{{ $t('2897201') }}</h1>
    <ActionButton class="button" @click="handleClick">{{ $t('f35b80e') }}</ActionButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ActionButton from '@/components/ActionButton'
import image from '@/assets/usercenter/big_success.png'
export default {
  name: 'Entry',
  components: {
    ActionButton,
  },
  data() {
    return {
      image,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    handleClick() {
      this.$emit('next')
    }
  }
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 50px 0;
  img {
    width: 72px;
    height: 72px;
  }
  h1 {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 32px;
    padding-top: 24px;
    padding-bottom: 40px;
  }
}
.button {
  width: 360px;
}
</style>
