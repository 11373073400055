<template>
  <div class="container">
    <el-form
      ref="form"
      label-suffix="："
      label-width="150px"
      :model="values"
      :rules="rules"
    >
      <el-form-item :label="$t('9de924b')" prop="newEmail">
        <el-input
          v-model="values.newEmail"
          placeholder="请输入新邮箱地址"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item :label="$t('d34532d')" prop="code">
        <el-input
          v-model="values.code"
          :placeholder="$t('5c41c2f')"
          style="width: 360px"
        >
          <template slot="append">
            <GraphicalCode />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('58edade')" prop="emailCode">
        <el-input v-model="values.emailCode" :placeholder="$t('edccf94')" style="width: 360px">
          <span
            slot="append"
            class="send-code-btn"
          ><CountDown :async-call="sendCode">{{ $t('2ad10e6') }}</CountDown></span>
        </el-input>
      </el-form-item>
    </el-form>
    <ActionButton
      class="button"
      :disabled="disabled"
      @click="changeEmail"
    >{{ $t('127e973') }}</ActionButton>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ActionButton from '@/components/ActionButton'
import GraphicalCode from '@/components/GraphicalCode'
import CountDown from '@/components/CountDown'

import api from '@/api/account'

export default {
  name: 'SetNewEmail',
  components: {
    ActionButton,
    GraphicalCode,
    CountDown,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      phone: '',
      values: {},
      rules: {
        newEmail: [{ required: true, message: '请输入新邮箱地址' }],
        code: [{ required: true, message: this.$t('5c41c2f') }],
        emailCode: [{ required: true, message: this.$t('edccf94') }],
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    disabled() {
      const a = this.values.newEmail
      const b = this.values.code
      const c = this.values.emailCode
      return !(a && b && c)
    },
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    handleClick() {
      this.$emit('next')
    },
    sendCode() {
      return new Promise((resolve, reject) => {
        this.$refs['form'].validateField('newEmail', (msg) => {
          if (!msg) {
            return api
              .getEmailCode({ email: this.values.newEmail, type: 'CHANGE' })
              .then((res) => {
                resolve(res.code === '000000')
              })
          } else {
            resolve(false)
          }
        })
      })
    },
    changeEmail() {
      api.changeEmail({
        newEmail: this.values.newEmail,
        oldEmail: this.userInfo.email,
        emailCode: this.values.emailCode,
        code: this.values.code,
      }).then(res => {
        // debugger
        if (res && res.code === '000000') {
          this.$emit('next')
          this.$message.success('修改成功')
          this.changeUserInfo({ email: this.values.newEmail })
        } else {
          this.$message.warning(res.message)
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 510px;
  margin: 60px auto;
  /deep/ .el-input-group__append {
    padding: 0;
  }
}
.button {
  width: 360px;
}
.send-code-btn {
  cursor: pointer;
  padding: 0 10px;
  display: inline-block;
  width: 80px;
  text-align: center;
}
</style>
