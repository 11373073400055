<template>
  <div class="container">
    <el-form
      ref="form"
      label-suffix="："
      label-width="150px"
      :model="values"
      :rules="rules"
    >
      <el-form-item :label="$t('a32ee8f')" prop="newPhone">
        <el-input
          v-model="values.newPhone"
          :placeholder="$t('f250848')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item :label="$t('d34532d')" prop="code">
        <el-input
          v-model="values.code"
          :placeholder="$t('5c41c2f')"
          style="width: 360px"
        >
          <template slot="append">
            <GraphicalCode />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('01fdd9f')" prop="smsCode">
        <el-input v-model="values.smsCode" :placeholder="$t('41dad45')" style="width: 360px">
          <span
            slot="append"
            class="send-code-btn"
          ><CountDown :async-call="sendCode">{{ $t('2ad10e6') }}</CountDown></span>
        </el-input>
      </el-form-item>
    </el-form>
    <ActionButton
      class="button"
      :disabled="disabled"
      @click="changePhone"
    >{{ $t('127e973') }}</ActionButton>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ActionButton from '@/components/ActionButton'
import GraphicalCode from '@/components/GraphicalCode'
import CountDown from '@/components/CountDown'

import api from '@/api/account'

export default {
  name: 'SetNewPhone',
  components: {
    ActionButton,
    GraphicalCode,
    CountDown,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      phone: '',
      values: {},
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    disabled() {
      return !this.values.code || !this.values.smsCode || !this.values.newPhone
    },
    rules() {
      return {
        newPhone: [{ required: true, message: '请输入新手机号码' }],
        code: [{ required: true, message: this.$t('5c41c2f') }],
        smsCode: [{ required: true, message: this.$t('41dad45') }],
      }
    }
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    handleClick() {
      this.$emit('next')
    },
    sendCode() {
      return new Promise((resolve, reject) => {
        this.$refs['form'].validateField('newPhone', (msg) => {
          if (!msg) {
            return api
              .sendSms({ phone: this.values.newPhone, smsType: this.type })
              .then((res) => {
                resolve(res.code === '000000')
              })
          } else {
            resolve(false)
          }
        })
      })
    },
    changePhone() {
      api.changePhone({
        newPhone: this.values.newPhone,
        oldPhone: this.userInfo.phone,
        smsCode: this.values.smsCode,
        code: this.values.code,
      }).then(res => {
        // debugger
        if (res && res.code === '000000') {
          this.$emit('next')
          this.$message.success('修改成功')
          this.changeUserInfo({ phone: this.values.newPhone })
        } else {
          this.$message.warning(res.message)
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 510px;
  margin: 60px auto;
  /deep/ .el-input-group__append {
    padding: 0;
  }
}
.button {
  width: 360px;
}
.send-code-btn {
  cursor: pointer;
  padding: 0 10px;
  display: inline-block;
  width: 80px;
  text-align: center;
}
</style>
