<template>
  <div class="container">
    <el-form label-suffix="：" label-width="150px">
      <el-form-item :label="$t('e4ef183')">
        <el-input :value="userInfo.phone" style="width:360px;" disabled />
      </el-form-item>
      <ActionButton class="button" @click="handleClick">{{ $t('870f156') }}</ActionButton>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ActionButton from '@/components/ActionButton'

export default {
  name: 'Entry',
  components: {
    ActionButton,
  },
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      phone: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    handleClick() {
      this.$emit('next')
    }
  }
}
</script>

<style scoped lang="less">
.container {
  width: 510px;
  margin: 0 auto;
  text-align: right;
  margin-top: 60px;
}
.button {
  width: 360px;
}
</style>
