<template>
  <div>
    <Steps :steps-content="stepConfig" :active="step - 1" />
    <EmailCheckPage
      v-if="step === 1"
      :index="1"
      type="CHANGE"
      @next="nextPage"
      @change="$emit('change')"
    />
    <ChangePasswordPage v-if="step === 2" :index="2" @next="nextPage" />
    <SuccessPage v-if="step === 3" @next="complete" />
  </div>
</template>

<script>
import Steps from '@/components/Steps/steps'

import EmailCheckPage from '../components/EmailCheckPage'
import ChangePasswordPage from '../components/ChangePasswordPage'
import SuccessPage from '../components/ChangePasswordSuccessPage'

export default {
  name: 'MsgCheck',
  components: {
    Steps,
    EmailCheckPage,
    ChangePasswordPage,
    SuccessPage,
  },
  data() {
    return {
      step: 1
    }
  },
  computed: {
    stepConfig() {
      return {
        step1: '验证身份',
        step2: '更改密码',
        step3: '成功',
      }
    }
  },
  methods: {
    nextPage() {
      // debugger
      this.step += 1
    },
    complete() {
      this.$emit('complete')
    },
  },
}
</script>

<style>
</style>
