<template>
  <img :src="src" @click="refresh" />
</template>
<script>
const src = '/api/frLegalPlatform/common/getVerify'
export default {
  name: 'GraphicalCode',
  data() {
    return {
      src,
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.src = `${src}?timestamp=${(new Date()).getTime()}`
    }
  },
}
</script>

<style>

</style>
