<template>
  <div class="steps" :class="classObj">
    <div class="item step1">{{ stepsContent.step1 }}</div>
    <div class="item step2">{{ stepsContent.step2 }}</div>
    <div class="item step3">{{ stepsContent.step3 }}</div>
  </div>
</template>

<script>
export default {
  name: 'Steps',
  props: {
    stepsContent: {
      type: Object,
      default: () => {
        return {
          step1: '验证企业',
          step2: '填写账号信息',
          step3: '完成',
        }
      },
    },
    active: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    classObj() {
      return `steps${this.active}`
    },
  },
  methods: {},
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@text-color: rgba(0, 0, 0, 0.85);
.steps {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.44);
  .item {
    box-sizing: border-box;
    position: relative;
    height: 32px;
    margin: 0 235px 0 40px;
    line-height: 32px;
    &::before {
      content: '';
      position: absolute;
      left: -40px;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
    }
    &::after {
      content: '';
      position: absolute;
      right: -218px;
      top: 50%;
      transform: translateY(-50%);
      width: 202px;
      height: 1px;
      background: #ddd;
    }
  }
  .step1 {
    font-weight: 500;
    color: @text-color;
    &::before {
      background: url('~@/assets/account/active1.png');
      background-size: 32px 32px;
    }
  }
  .step2 {
    &::before {
      background: url('~@/assets/account/defalut2.png');
      background-size: 32px 32px;
    }
  }
  .step3 {
    margin-right: 0;
    &::before {
      background: url('~@/assets/account/defalut3.png');
      background-size: 32px 32px;
    }
    &::after {
      display: none;
    }
  }
}
.steps1 {
  .step1 {
    &::before {
      background: url('~@/assets/account/complete.png');
      background-size: 32px 32px;
    }
    &::after {
      background: url('~@/assets/account/lineActive.png');
    }
  }
  .step2 {
    font-weight: 500;
    color: @text-color;
    &::before {
      background: url('~@/assets/account/active2.png');
      background-size: 32px 32px;
    }
  }
}
.steps2 {
  .step1 {
    &::before {
      background: url('~@/assets/account/complete.png');
      background-size: 32px 32px;
    }
    &::after {
      background: url('~@/assets/account/lineActive.png');
    }
  }
  .step2 {
    font-weight: 500;
    color: @text-color;
    &::before {
      background: url('~@/assets/account/complete.png');
      background-size: 32px 32px;
    }
    &::after {
      background: url('~@/assets/account/lineActive.png');
    }
  }
  .step3 {
    font-weight: 500;
    color: @text-color;
    &::before {
      background: url('~@/assets/account/active3.png');
      background-size: 32px 32px;
    }
  }
}
</style>
