<template>
  <Card
    :container-style="{ padding: '0' }"
    :body-style="{ height: '100%' }"
    no-line
  >
    <template slot="header"> 帐号安全 </template>
    <el-tabs v-model="activeName" class="tab" @tab-click="handleClick">
      <el-tab-pane :label="$t('e4ef183')" name="mobile">
        <Mobile />
      </el-tab-pane>
      <el-tab-pane :label="$t('b44fa29')" name="email">
        <Email />
      </el-tab-pane>
      <el-tab-pane :label="$t('11b3b3b')" name="passwd">
        <PassWord />
      </el-tab-pane>
    </el-tabs>
  </Card>
</template>

<script>
import Card from '@/components/Card'
import Mobile from './mobile'
import Email from './email'
import PassWord from './passwd'

export default {
  name: 'Organization',
  components: {
    Card,
    Mobile,
    Email,
    PassWord,
  },
  data() {
    return {
      activeName: 'mobile',
    }
  },
  methods: {
    handleClick() {},
  },
}
</script>

<style scoped lang="less">
.tab {
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
  }
  /deep/ .el-tabs__nav-scroll {
    padding: 0 30px;
  }
  /deep/ .el-tabs__item {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
