<template>
  <div>
    <Steps :steps-content="stepConfig" :active="step - 1" />
    <EmailCheckPage
      v-if="step === 1"
      :index="1"
      type="CHANGE"
      @next="nextPage"
      @change="$emit('change')"
    />
    <SetNewPhone
      v-if="step === 2"
      :index="2"
      type="CHANGE_PHONE"
      @next="nextPage"
    />
    <SuccessPage v-if="step === 3" @next="complete" />
  </div>
</template>

<script>
import Steps from '@/components/Steps/steps'

import EmailCheckPage from '../components/EmailCheckPage'
import SetNewPhone from '../components/SetNewPhonePage'
import SuccessPage from '../components/ChangePhoneSuccessPage'

export default {
  name: 'EmailCheck',
  components: {
    Steps,
    EmailCheckPage,
    SetNewPhone,
    SuccessPage,
  },
  data() {
    return {
      step: 1,
      stepConfig: {
        step1: '验证身份',
        step2: '绑定手机',
        step3: '绑定成功',
      },
    }
  },
  methods: {
    nextPage() {
      this.step += 1
    },
    complete() {
      this.$emit('complete')
    }
  },
}
</script>

<style>
</style>
