<template>
  <div class="container">
    <img :src="image" alt="" />
    <h1>{{ $t('906fb75') }}</h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import image from '@/assets/usercenter/big_success.png'
export default {
  name: 'SuccessPage',
  data() {
    return {
      image,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 50px 0;
  img {
    width: 72px;
    height: 72px;
  }
  h1 {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 32px;
    padding-top: 24px;
    padding-bottom: 8px;
  }
}
</style>
